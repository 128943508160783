.app{
    width: 100%;
}

.header-banner{
    padding: 5px 0;
    width: 100%;
    color: #000
}
.header-brand{
display: flex;
justify-content: space-between;
}

.contact-item{
    display: flex;
    margin: 0 10px;
    width:275px;
    align-items: flex-start;
}

.contact-item-title{
font-size: 16px;
text-transform: uppercase;
}

.contact-item-subtitle{
font-size: 14px;
}
.site-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.site-menu{
    display: flex;
    align-items: center;
}
.site-menu li{
    margin: 0px 16px;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
}
.site-menu li a:after
{
content: "";
    position: absolute;
    top: 100%;
    height: 2px;
    background-color: #8ad169;
    width: 100%;
    left: 0;
    opacity: 0;
    transition: .3s ease;
    border-radius: 1em;
}

.site-menu li a:hover:after,
.site-menu li a.active:hover:after,
.site-menu li a.active:after
{
    opacity: 1;
}
.site-menu li a.active{
    color: #8ad169;
}
.site-menu li > a{
    font-family: "Sohn Bold",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
}

.site-menu li:hover > a{
    color: #8ad169;
}
.header-contents{
    border-top: 1px solid #ccc;
}
.show-responsive{
    display: none;
}
.nav-label,
#toggle-responsive-menu,#toggle-dropdown-menu {
  display: none;
}

.dropdown-menu{
    position: relative;
}
.dropdown{
position: absolute;
display: none;
        position: absolute;
        background-color: #fff;
        width: 250px;
        z-index: 100;
        padding: 10px;
        border-radius:0 0 2px 2px;
        box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
}
.dropdown li{
    margin: 16px 0;
}
.dropdown li a,
.dropdown li a:hover
{
    color: #21334d;
}
    #toggle-dropdown-menu:checked + #about-menu {
        display: block;
        animation: animatezoom 1s;
    }
#about-menu{
        top: 100%;
        right: 0;
}
.icon{
  width: 20px;
  height: 20px;
}
.logo{
  width: 81px;
    height: 26px;
}

.hero{
    background-color: #000;
    width: 100%;
    position: relative;
}
/*.hero:after{
    position: absolute;
    content: "";
    background-color: rgba(0,0,0,.4);
    width: 100%;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}*/
.carousel-content-container,
.carous .slider-items-container .carous-image img,
.hero-content-container{
    height: 72vh;
}
.carousel-content{
    position: absolute;
    z-index: 6;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}



.carous .slider-items-container .carous-image img{
width: 100%;
object-fit: cover;
object-position: center;
}
.carous .slider-items-container{
    padding: 0 !important;
}

.hero-content-container{
    width: 100%;
    display: flex;
}
.hero-content-inner{
margin: auto;
    width: 600px;
    background-color: rgba(194, 203, 213, .8);
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 0 20px 10px rgba(0,0,0,.2);
    position: relative;
    z-index: 7;
}
.hero-body{
    font-weight: 600;
}
.brand-address{
    padding: 5px 0;
    text-align: center;
color: #21334d;
border-radius: 2px;
font-size: 1em;
}


.socials{
    display: flex;
    flex-flow: column;
    flex: 1;
    max-width: 500px;
}

.socials a{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.brick-title p{
font-size: 24px;
}
.brick{
        width: 60px;
    height: 10px;
    background-color: rgba(0,128,0,1);
    border-radius: 2px;
}

.ctas-container{
    justify-content: space-around;
    align-items: center;
}

.ctas-container .button{
    width: 200px;
    text-transform: uppercase;
}

.hero-body ul{
    list-style: inside decimal;
}

.sided-section-container{
    justify-content: space-between;
    align-items: flex-start;
}

.sided-section-container .text-side.left{
width: 57%;
padding-right: 20px;

}
.sided-section-container .text-side.left .side-body,
.sided-section-container .text-side.left .side-footer{
    padding-left: 10%;
}
.side-head{
    width: 250px;
    text-align: right;
    border-bottom: 4px solid rgba(0,128,0,1);
    padding-bottom: 4px;
}
.sided-section-container .image-side{
width: 40%;
position: relative;
box-shadow: 0 0 3px 1px rgba(0,0,0,.3);
margin-top: 50px;
}
.sided-section-container .image-side:after{
    position: absolute;
    content: "";
    background-color: rgba(0,0,0,.4);
    width: 100%;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.section-title{
    font-size: 2em;
}

.image-side-float-content{
    position: absolute;
    z-index: 6;
    box-shadow: 0 0 20px 10px rgba(0,0,0,.2);
    border-radius: 2px;
    width: 100%;
    height: 100%;
    padding: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    margin: auto;
    background-color: rgba(0,128,0,.7);
    text-align: center;
}
.missvis{
margin-top: 150px;
}
.mission{
    margin-top: 50px;
}
.image-side-text{
    position: relative;
    margin: 2px 0 0 2px;
    z-index: 7;
    font-size: 16px;
}

.section-button{
border: 1px solid #ccc;
background-color: transparent;
color: inherit;
box-shadow: none;
}

.gallery-container figure{
margin: 16px 0;
border: 2px solid rgba(0,128,0,.3);
padding: 8px;
border-radius: 2px;
}
.gallery-container figure img{
    border-radius: 2px;
}
.gallery-container figure figcaption{
    font-size: 14px;
    text-align: center;
}

.faqs-container{
    flex-flow: row wrap;
    justify-content: space-around;
    display: flex;
}
.faqs-container li.faq{
    flex: 100% 1 1;
    max-width: 500px;
    min-width: 300px;
    margin: 0 0 32px 0;
    padding: 4px;
border-top: 1px solid rgba(0,128,0,.3);
border-right: 1px solid rgba(0,128,0,.3);
border-radius: 4px;
}
.page-faqs{
    padding: 20px;
}
.page-faqs .faqs-container li.faq{
    max-width: 100%;
    width: 100%;
}

.buttons-container{
    justify-content: space-between;
}

.buttons-container .button{
width: 220px;
text-transform: uppercase;
}
.faqs-container li.faq ul{
    list-style-type: square;
}
.project{
    width: 280px;
    margin: 20px 10px;
    margin-bottom: 40px;
}
.project-name{
    color: #fff;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    margin-top:  20px;
}
.project-details{
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    padding-bottom: 0;
}

.project-image img{
    height: 170px;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}


.team-items-container{
    justify-content: space-around;
}
.team-card{
    width: 350px;
    padding: 30px 0 8px 0;
    position: relative;
    box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
    margin: 10px 0;
}

.bars{
    position: absolute;
    content: "";
    background-color: rgba(0,128,0,.3);
    width: 100%;
    height: 1px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
}
.bar1{
    top: 15%;
}
.bar2{
    top: 30%;
}
.bar3{
    top: 45%;
}
.team-button{
    width: 100%;
    border-radius: 1px;
    background-color:rgba(0,128,0,.3);
    box-shadow: none;
}

.team-details,.team-image{
    position: relative;
    z-index: 3;
}
.team-social-icons{
    justify-content: space-around;
    margin-top: 10px;
}

.team-social-icons img{
    
    cursor: pointer;
}
.team-image{
    width: 80%;
    margin: auto;
}

.team-image img{
    border-radius: 2px;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
    height: 300px;
    object-fit: cover;
    object-position: top;
    background-color: #21334d;
}
.team-footer{
    border-top: 1px solid rgba(0,128,0,.3);
    margin-top: 20px;
    padding-top: 10px;
}
.team-social-icons p{
    border: 1px solid #ccc;
    border-radius: 2px;
}



.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 5px 0;
}

.slider-items-container .slick-arrow {
  background-color: #8ad169 !important;
  border-radius: 2px;
  height: 32px;
  width: 50px;
  top: 100%;
}
.carous .slick-arrow{
    background-color: transparent;
}


.slider-items-container .slick-arrow:hover {
  background-color: #000 !important;
}

.slider-items-container.center-slide-arrows .slick-arrow {
  top: 37% !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}

.slider-items-container .slick-next {
  right: 0px;
}

.slider-items-container .slick-prev {
  left: 0px;
}

.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 90% !important;
}
.carous .slider-items-container .slick-next {
  right: 20px;
}

.carous .slider-items-container .slick-prev {
  left: 20px;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}


.detail-item,.text-details p{
    margin: 10px 0;
}
.text-details p{
    margin-left: 10px;
}
.detail-item li,.member-step-content li,.about-ul li{
    list-style-type: square;
    list-style-position: inside;
    margin-left: 10px;
    padding: 10px 0;
}

.member-step{
    width: 10%;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0,128,0,.3);  
}
.step-number{
 color: rgba(0,128,0,.3); 
    border-bottom: 2px solid rgba(0,128,0,.3); 
}

.border{
    width: 50px;
    height: 3px;
    margin: 3px 0;
}
.text-content,.images-content{
    padding: 20px;
    line-height: 1.5;
}

.text-content p,.text-content ol{
margin-top: 10px;
font-size: 1.1em;
}

.text-content ol{
    list-style-type: decimal;
    list-style-position: inside;
}
.text-content li{
    padding: 10px 0;
}

.footer-menu{
    justify-content: space-between;
}

.footer-menu-title{
    color: #fff;
    margin-bottom: 16px;
    font-size: 18px;
    text-transform: uppercase;
}

.footer-menu-item ul li{
padding: 10px 5px;
border-bottom: 1px dashed rgba(194, 203, 213, 1);
}
.footer-menu-item ul li:first-child{
    padding-top: 0;
}
.footer-menu-item ul li a{
    color: #ccc;
    text-decoration: none;

}
.footer-menu-item ul li a:hover{
    color: #ffdb00;
    transition: all 0.3s ease 0s;
padding-left: 15px;
}
.footer-menu-item ul li a span.book-title-footer{
display: block;
font-family: "Sohn Bold",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
    color: #eee;
    margin-bottom: 5px;
}
.footer-useful{
    width: 300px;
}
.coming{
    background-color: #ffdb00;
    color: #21334d;
    padding: 2px 4px;
    padding-top: 0;
    border-radius: 2px;
}

.footer-menu-item ul li a span.book-subtitle-footer{
margin-top: 5px;
}

.footer-about{
    width: 300px;
}



@media screen and (min-width: 960px) and (max-width: 1150px){

.project-banner{
    width: 100%;
    text-align: center;
}
.contact-item{
    width: 200px;
}
.faqs-container li.faq{
    max-width: 600px;
}
.footer-menu-item{
    width: 300px;
}
.missvis {
  margin-top: 60px;
}
}

@media screen and (min-width: 800px) and (max-width: 959px) {
    .site-menu li{
        margin: 0px 6px;
    }
    .sided-section-container .text-side.left .side-body{
    padding-left: 0;
}
.contact-item{
    width: 170px;
}
.contact-item-title{
    font-size: 14px;
}
.missvis{
    margin-top: 30px;
}
.faqs-container li.faq{
    max-width: 600px;
}
  .contacts-container{
            display: flex;
            flex-flow: column;
            justify-content: center;
            text-align: center;
        }
         .socials{
            flex: auto;
        width: fit-content;
        max-width: auto;
        margin-top: 20px;
        margin: auto;
        }
        .contacts-container div.working,
        .contacts-container div.address
        {
            margin-bottom: 40px;
        }
.footer-menu-item{
    width: 300px;
}
.footer-useful{
    width: 250px;
    margin: 0 20px;
}
}

@media screen and (min-width: 600px) and (max-width: 799px){
   .menu-container{
       position: relative;
   }
   .contact-item{
    width: 170px;
}
.contact-item-title{
    font-size: 14px;
}

.header-brand {
  justify-content: center;
  flex-flow: row wrap;
}
.site-brand{
    margin-bottom: 10px;
}
.contact-item-subtitle {
  font-size: 12px;
}
    .sided-section-container{
        flex-flow: row wrap;
        width: 80%;
        margin: auto;
    }
    .sided-section-container .text-side.left{
        width: 100%;
            }
            .sided-section-container .image-side{
                width: 100%;
            }
            .sided-section-container .text-side.left .side-body{
            padding-left: 0;
            text-align: justify;
        }
        
        .side-head{
        width: 300px;
        text-align: center;
        margin: auto;
        }
        .sided-section-container .text-side.left .side-footer{
            text-align: center;
        }
        .faqs-container li.faq{
            max-width: 600px;
        }
       
        .footer-menu-item{
            width: 300px;
        }
        .footer-useful{
            width: 250px;
            margin: 0 20px;
        }
        .missvis{
    margin-top: 100px;
}
  .projects-container {
  justify-content: center;
}
.project{
    width: 500px;
}
.project-image img{
height: 250px;
}
    .site-menu li.menu-cta{
        width: 100%;
    }
    .site-menu li.menu-cta:hover:after{
        opacity: 0;
    }


        .team-card{
            max-width: 350px;
            min-width: 280px;
            width: 100%;
            margin: 10px 8px;
        }
         .contacts-container{
            display: flex;
            flex-flow: column;
            justify-content: center;
            text-align: center;
        }
         .socials{
            flex: auto;
        width: fit-content;
        max-width: auto;
        margin-top: 20px;
        margin: auto;
        }
        .contacts-container div.working,
        .contacts-container div.address
        {
            margin-bottom: 40px;
        }
       

        .width-800,.width-960{
            max-width: 768px;
            width: 96%;
                    }

            .width-600, .modal-600{
    width: 96%;
            }
            .team-cards-container{
                justify-content: center;
            }

            .member-step .title{
                font-size: 28px;
            }
             .slider-items-container .slick-next {
    right: 20px;
  }

  .slider-items-container .slick-prev {
    left: 20px;
  }
  
#about-menu{
        top: 100%;
        right: auto;
        left: 0;
}
}

@media screen and (max-width: 599px){
  
  .header-brand {
  justify-content: space-between;
  flex-flow: row nowrap;
}
    .responsive-menu-container{
       position: relative;
   }
      .contact-item{
    width: 100%;
    padding: 10px 0;
}

.header-contents,#responsive-contacts-details{
    display: none;
}
.contact-item-title{
    font-size: 14px;
}
.contact-details{
    flex-flow: column;
    border-top: 1px solid #ccc;
}


.site-brand{
    margin-bottom: 10px;
}
    .show-responsive{
        position: absolute;
        top: 100%;
        background-color: #fff;
        right: 0;
        width: 250px;
        z-index: 100;
        padding: 10px;
        border-radius:0 0 2px 2px;
        box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
    }
    #about-menu {
  width: 140px;
}
.show-responsive .site-menu{
    display: flex;
    flex-flow: column;
}

.site-menu{
    
        padding-bottom: 16px;
}
    .site-menu li{
        margin: 16px 0;
        padding: 5px 0;
        width: fit-content;
        display: block;
    }
    .site-menu li.menu-cta{
        width: 100%;
    }
    .site-menu li.menu-cta:hover:after{
        opacity: 0;
    }

    .nav-label {
        display: block;
font-weight: 600;
font-size: 35px;
color: #fff;
cursor: pointer;
letter-spacing: 2px;
width: 35px;
height: 39px;
line-height: 1;
background-color: #000;
    }

    #toggle-responsive-menu:checked + .show-responsive {
        display: block;
        animation: animatezoom 1s;
    }

.welcome-content{
    flex-flow: row wrap;
}

.welcome-content h2{
    margin-bottom: 15px;
}
    .sided-section-container{
        flex-flow: row wrap;
        width: 99%;
        margin: auto;
    }
    .sided-section-container .text-side.left{
        width: 100%;
            }
            .sided-section-container .image-side{
                width: 100%;
            }
            .sided-section-container .text-side.left .side-body{
            padding-left: 0;
            text-align: justify;
        }
      .missvis{
          margin-top: 20px;
      }
        .side-head{
        width: 100%;
        text-align: center;
        margin: auto;
        }
        .sided-section-container .text-side.left .side-footer{
            text-align: center;
        }
        .faqs-container li.faq{
            max-width: 600px;
        }
         .contacts-container{
            display: flex;
            flex-flow: column;
            justify-content: center;
            text-align: center;
        }
         .socials{
            flex: auto;
        width: fit-content;
        max-width: auto;
        margin-top: 20px;
        margin: auto;
        }
        .contacts-container div.working,
        .contacts-container div.address
        {
            margin-bottom: 40px;
        }
        .footer-menu-item{
            width: 300px;
        }
        .footer-useful{
            width: 250px;
            margin: 0 20px;
        }
        .projects-container{
justify-content: center;
        }
        .project{
            margin-left: 0;
            margin-right: 0;
            width: 300px;
        }
        .team-card{
            max-width: 290px;
            min-width: 280px;
            width: 100%;
            margin: 10px 8px;
        }
        .contacts-container{
            display: flex;
            justify-content: center;
        }

        .width-800,.width-960{
            max-width: 768px;
            width: 96%;
                    }

            .width-600, .modal-600{
    width: 96%;
            }
            .team-cards-container,.team-items-container{
                justify-content: center;
                flex-flow: row wrap;
            }

            .member-step .title{
                font-size: 28px;
            }
            .footer-menu{
                flex-flow: row wrap;
            }
            .footer-menu-item{
                width: auto;
                margin: 20px 0;
            }
            .footer-about{
                width: 300px;
            }
            .brand-name h1{
                font-size: 20px;
            }
            .brand-slogan p{
                font-size: 12px;
            }
            .sided-section-container .text-side.left{
                padding-right: 0;
            }
            .sided-section-container .text-side.left .side-footer{
padding-left: 0;
            }
            .faqs-container li.faq{
                width: 100%;
                min-width: 100%;
                max-width: 100%;
            }

            .hero-content-inner{
                width: 100%;
            }
            .ctas-container {
  flex-flow: column;
}
.ctas-container .button,
.buttons-container .button
{
    margin: 10px 0;
}
.buttons-container .button{
    width: 100%;
}
.buttons-container {
    justify-content: space-between;
    flex-flow: row wrap;
}
  .slider-items-container .slick-next {
     right: 10px;
   }

   .slider-items-container .slick-prev {
     left: 10px;
   }
#toggle-dropdownresponsive-menu{
    display: none;
}
       #toggle-dropdownresponsive-menu:checked + #about-menu {
        display: block;
        animation: animatezoom 1s;
    }
#about-menu{
        top: 100%;
        right: 0;
}
   #about-menu{
            top: 100%;
    right: auto;
    left: 0px;
    width: 120px;
}
.carousel-content-container,
.carous .slider-items-container .carous-image img,
.hero-content-container{
    height: 90vh;
}
.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 95% !important;
}
.carous .slider-items-container .carous-image img{
width: 100%;
object-fit: cover;
object-position: bottom;
}
}